import { useCallback, useEffect, useState, useContext } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { FilterSection } from '@vyce/core/src/types';
import { getWorkerTimeLogsByShiftRequest } from '@vyce/core/src/api/time';
import { EmployeeTimeLogsContext } from '@vyce/core/src/contexts';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { Props } from '../types';
import { getTimeLogsFilter, transformData } from '../utils';

const nowDate = dayjs();
const defaultSortModel: GridSortModel = [{ field: 'work_day', sort: 'desc' }];

export const useEmployeeTimeLogsData = ({ userId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [timeLogsData, setTimeLogsData] = useState<ReturnType<typeof transformData>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [dateFrom, setDateFrom] = useState<string>(nowDate.subtract(7, 'day').startOf('day').format());
  const [dateTo, setDateTo] = useState<string>(nowDate.endOf('day').format());
  const [filtersSections, setFiltersSections] = useState<FilterSection[]>([]);

  const { first_name } = useContext(EmployeeTimeLogsContext);
  const history = useHistory();
  const { sortModel, offset, total, handleSortModelChange, handlePageChange } = useTable({
    defaultSortModel,
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getWorkerTimeLogsByShiftRequest(userId, {
        end_date: dayjs(dateTo).format('YYYY-MM-DD'),
        start_date: dayjs(dateFrom).format('YYYY-MM-DD'),
        site_id: filters?.location?.id ? filters.location.id : undefined,
      });
      const transformedDateItems = transformData(data.date_items);
      setTimeLogsData(transformedDateItems);
      if (filtersSections.length === 0) {
        setFiltersSections(getTimeLogsFilter(transformedDateItems));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [offset, sortModel, userId, filters, dateTo, dateFrom]);

  const handleFilterChange = (newFilters: any) => {
    if (isEqual(newFilters, filters)) {
      return;
    }
    setFilters(newFilters);
  };

  const goToDailyActivity = ({
    companyId,
    shiftId,
    userId,
    date,
  }: {
    companyId: string;
    shiftId: string;
    userId: string;
    date: string;
  }) => {
    history.push(`${history.location.pathname}/${companyId}/${shiftId}/${first_name}_${userId}/${date}`);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    total,
    timeLogsData,
    loading,
    sortModel,
    filters,
    filtersSections,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    handleFilterChange,
    goToDailyActivity,
    handlePageChange,
    handleSortModelChange,
  };
};
