import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  FormControlLabel,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppCheckbox } from '@vyce/core/src/components/inputs/AppCheckbox';
import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxHeight: '560px',
  },
  dialogContent: {
    height: '100%',
    overflow: 'auto',
  },
  formControlLabelRoot: {
    maxWidth: '250px',
    '& .MuiFormControlLabel-label': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  filterButton: {
    width: '60px',
  },
}));

const FilterBlock = ({
  title,
  type,
  options,
}: {
  title: string;
  type: string;
  options: { id: string; name: string }[];
}) => {
  const { control } = useFormContext();
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" gridGap={8} overflow="auto">
      <Typography variant="subtitle2">{title}</Typography>
      {options.map(item => (
        <Controller
          key={item?.id}
          name={`${type}.${item?.id}`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <AppCheckbox
                  onChange={e => field.onChange(e.target.checked)}
                  color="primary"
                  variant="rectangle"
                  checked={field.value === undefined ? true : field.value}
                />
              }
              label={item?.name}
              className={classes.formControlLabelRoot}
            />
          )}
        />
      ))}
    </Box>
  );
};

export const FiltersDialog = ({
  isOpen,
  loading,
  filterSettings,
  onSubmit,
  onClose,
  restoreFilters,
}: {
  isOpen: boolean;
  loading: boolean;
  filterSettings: {
    options: { id: string; name: string }[];
    title: string;
    type: string;
  }[];
  onClose: () => void;
  onSubmit: () => void;
  restoreFilters: () => void;
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  const isFiltersExists = filterSettings.some(item => item.options.length);
  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.paper }}>
      <DialogTitle id="responsive-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={32}>
          <Typography>Didn't Clock Out - Filters</Typography>
          <Button variant="outlined" color="primary" size="small" onClick={restoreFilters}>
            Restore to default
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box display="flex" gridGap={16} height="360px" marginTop={2}>
          {isFiltersExists ? (
            filterSettings
              .filter(item => item.options.length)
              .map((item, index) => (
                <Box key={item.type} display="flex" gridGap={16} width="100%">
                  {index ? <Divider orientation="vertical" flexItem /> : null}
                  <FilterBlock key={item.type} title={item.title} options={item.options} type={item.type} />
                </Box>
              ))
          ) : (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              No filters...
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={classes.filterButton}
          onClick={() => onSubmit()}
          disabled={loading}>
          <ButtonTitleWithLoading title="Filter" loaderVariant="paper" loading={!!loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
