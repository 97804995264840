import React from 'react';

import { Box, makeStyles, useTheme } from '@material-ui/core';

import { monochrome } from '../theme/styles';
import { DetailsIcon } from './DetailsIcon';
import { AppCustomTableColumn } from '../types';

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px',
    display: 'flex',
    borderRadius: '8px 8px 0 0',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.type === 'light' ? monochrome.lightest : monochrome.darkest,
    color: theme.palette.type === 'light' ? monochrome.mediumdark : monochrome.mediumlight,
    fontWeight: 500,
    fontSize: '13px',
  },
  row: {
    padding: '16px 24px',
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
  },
  hint: {
    maxWidth: 300,
  },
}));

interface Props {
  columns: AppCustomTableColumn[];
  data: any[];
}

export const AppCustomTable: React.FC<Props> = ({ columns, data }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor={theme.palette.background.paper}
      borderRadius={'8px 8px 0 0'}>
      <Box className={classes.header} width="100%">
        {columns.map((col, i) => (
          <Box
            key={i + 'header'}
            flex={col.flex}
            minWidth={col.width || 'auto'}
            display="flex"
            justifyContent={col.justifyContent}
            gridGap={8}>
            {col.title}

            {col.hint && <DetailsIcon size="16px" popoverClass={classes.hint} content={<>{col.hint}</>} />}
          </Box>
        ))}
      </Box>

      {data.map((row, i) => (
        <Box className={classes.row} display="flex" key={i + 'row'}>
          {columns.map((col, j) => (
            <Box key={j + 'cell'} flex={col.flex} minWidth={col.width || 'auto'}>
              {col.renderCell && col.renderCell(row)}
              {col.valueGetter && col.valueGetter(row)}
              {!col.valueGetter && !col.renderCell && row[col.name]}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
